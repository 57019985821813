import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import awsImage from "../images/aws.jpg"
import circleCiImage from "../images/circleci.png"
import graphQlImage from "../images/graphql.png"
import cloudFormationImage from "../images/cloudformation.png"
import githubImage from "../images/github.png"
import lambdaImage from "../images/lambda.png"
import materialUiImage from "../images/materialui.png"
import postgresImage from "../images/postgres.png"
import reactImage from "../images/react.png"
import sagemakerImage from "../images/sagemaker.png"
import typescriptImage from "../images/typescript.png"
import nodeImage from "../images/nodejslogo.png"

const title = "Bespoke software projects delivered immediately"
const subtitle = "Risk-free outsourcing, high quality codebases with serverless infrastructure, data-stores, and UI."

const body = <div>
    <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3 gradient-text ">
        How does it work?
    </h3>
    <p className="text-gray-600 mb-8">
        We discuss the requirements, then our developers write the code. It's a lot like any other software project, except for one major difference:
    </p>

    <p className="text-xl mb-8  gradient-text">
        Our developers are bots, so it only takes a few milliseconds.
    </p>

    <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3 gradient-text">
        What do I get?
    </h3>
    <p className="text-gray-600 mb-8">
        Source code. Usually a Github repo containing code, tests, and docs. Instantly deployable, with continuous integration and delivery.  <br />
        <span className="image-tray">
            <img src={githubImage} className="tech-image" />
            <img src={awsImage} className="tech-image" />
            <img src={nodeImage} className="tech-image" />
            <img src={postgresImage} className="tech-image" />
            <img src={typescriptImage} className="tech-image" />
            <img src={reactImage} className="tech-image" />
            <img src={lambdaImage} className="tech-image" />
            <img src={materialUiImage} className="tech-image" />
            <img src={sagemakerImage} className="tech-image" />
            <img src={circleCiImage} className="tech-image" />
            <img src={cloudFormationImage} className="tech-image" />
            <img src={graphQlImage} className="tech-image" />
        </span>
    </p>


    <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3 gradient-text">
        Why is it risk-free?
    </h3>
    <p className="text-gray-600 mb-8">
        Traditionally, writing software takes time. Usually more than you budgeted for. When it's built there's no guarantee the application will be as good as you wanted, and it maybe difficult to maintain.

    </p>
    <p className="text-xl mb-8  gradient-text">We deliver software on demand. You can use the app, and even review the code before committing to anything.</p>


    <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3 gradient-text">
        How long does it take?
    </h3>
    <p className="text-gray-600 mb-8">
        We could probably get it done today. But it could be a few days. We're not quite fully automated yet, we may have to join a few dots, or teach our bots a new UI component. But the tech's getting better every day, it'll soon be down to minutes.
    </p>



    <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3 gradient-text">
        What about quality?
    </h3>
    <p className="text-gray-600 mb-8">
        As well as providing a great user experience, we produce software that is a pleasure to extend and maintain. It's got incredibly high test coverage, and we care deeply about code quality. You can read about how we architect our applications <a href="/technical-documentation">here</a>. We use proven tech, and create serverless infrastructure wherever possible so you can host your application with the minimum overhead.
    </p>

    {/* <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3 gradient-text">
                                    Enough talking, can you show me an example?
                                </h3>
                                <p className="text-gray-600 mb-8">
                                    We are busy with this right now, come back soon to see it.
                                </p> */}

    <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3 gradient-text">
        How can we get in touch?
    </h3>
    <p className="text-gray-600 mb-8">
        Just say hello in the live chat, or email us at <a href="mailto:hello@rowlandstreet.com">hello@rowlandstreet.com</a>
    </p>
</div>


function IndexPage() {
    return (
        <Layout body={body} subtitle={subtitle} title={title}>
            <SEO
                title="Risk-free outsourcing"
                keywords={[`software`, `agile`, "developer", "contract", "project", "success"]}
            />
        </Layout>
    );
}

export default IndexPage;